/** @jsx jsx */
import {jsx, css} from "@emotion/react";
import Page from "../layouts/page";

const hero = css`
font-size: 4em;
font-weight: normal;
`

const rapidIxSpan = css`
color: #44718E;
`

const Resellers = () => {
    return <Page>
        <h1 css={hero}><span css={rapidIxSpan}>RapidIX</span> Resellers</h1>

        <p>The RapidIX Resellers Programme provides access to the IXP without colocation inside Interxion LON1. Our trunk port access can provide access to multiple autonomous systems from a single physical host, allowing for VPS and VM access to RapidIX.</p>
    </Page>
}

export default Resellers
